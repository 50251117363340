


































import {
  defineComponent,
  ref,
  onMounted
} from '@nuxtjs/composition-api';
import { useProduct } from '@wemade-vsf/catalog/lib/runtime/product/composables/useProduct';
import ProductCard from 'components/catalog/Product/ProductCard.vue';
import CtaHeading from 'components/theme/Content/CtaHeading.vue';
import { SfCarousel, SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductCarousel',
  props: {
    title: {
      type: String,
      default: ''
    },
    skus: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    }
  },
  components: {
    ProductCard,
    CtaHeading,
    SfCarousel,
    SfLoader
  },
  setup (props) {
    const { loading, getProductList } = useProduct()
    const skus = props.skus.split(',').map(s => s.trim()).filter(Boolean)
    const products = ref([])

    onMounted(async () => {
      if (skus.length === 0) return
      const baseSearchQuery = {
        filter: {
          sku: {
            in: skus
          }
        },
        pageSize: skus.length
      }
      const result = await getProductList(baseSearchQuery)
      products.value = result?.items ?? []
    })

    return {
      loading,
      products
    }
  }
})
