
















import {
  defineComponent,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CtaHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 2
    }
  },
  components: {
    SfHeading
  },
  setup (props) {
    const { localePath } = useContext()
    const hasLink = computed(() => !!props.link && !!props.linkText)
    const isExternal = computed(() => hasLink.value && props.link.startsWith('http'))
    const ctaLink = computed(() => {
      if (hasLink.value) {
        return isExternal ? props.link : localePath(props.link)
      }
      return ''
    })

    return {
      hasLink,
      isExternal,
      ctaLink
    }
  }
})
