























import {
  defineComponent,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { useUser } from '@wemade-vsf/customer'

export default defineComponent({
  name: 'ProductPrice',
  props: {
    prefix: {
      type: String,
      default: ''
    },
    showFrom: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    },
    isRange: {
      type: Boolean,
      default: false
    },
    regular: {
      type: [String, Number],
      default: null
    },
    special: {
      type: [String, Number],
      default: null
    },
    showOldPriceOnSpecial: {
      type: Boolean,
      default: true
    },
    oldPricePrefix: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { app, i18n } = useContext();
    const { isAuthenticated } = useUser();
    const { pricePrefix: globalPrefix, showOldPriceOnSpecial: globalShowOld, showOldPriceOnSpecialForAuthenticated, showPricePrefixOnlyForAuthenticated, priceRangePrefix } = app.$wm?.catalog

    const prefixString = computed(() => {
      let prefix = props.prefix || globalPrefix || '';
      if (showPricePrefixOnlyForAuthenticated && prefix === globalPrefix && !isAuthenticated.value) {
        prefix = ''
      }
      if (props.showFrom || (props.isRange && priceRangePrefix)) {
        return prefix ? `${i18n.t(prefix)} ${i18n.t(priceRangePrefix)}` : i18n.t(priceRangePrefix)
      }
      return i18n.t(prefix)
    })

    const showDiscounted = computed(() => {
      if (isAuthenticated) return showOldPriceOnSpecialForAuthenticated;
      return props.showOldPriceOnSpecial || globalShowOld
    })

    const regularPrice = computed(() => {
      if (props.special && !showDiscounted.value) return props.special
      return props.regular
    })
    const specialPrice = computed(() => {
      if (!showDiscounted.value) return null
      return props.special
    })

    return {
      prefixString,
      regularPrice,
      specialPrice
    }
  }
})
